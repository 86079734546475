import {
  mdiAccount,
  mdiAccountArrowRightOutline,
  mdiAccountBoxMultipleOutline,
  mdiAccountCheck,
  mdiAccountCircle,
  mdiAccountGroupOutline,
  mdiAccountHardHat,
  mdiAccountKeyOutline,
  mdiAccountMinusOutline,
  mdiAccountMultiple,
  mdiAccountMultipleOutline,
  mdiAccountMultipleRemoveOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiAccountStarOutline,
  mdiAccountSupervisorOutline,
  mdiAccountTieOutline,
  mdiAlignVerticalBottom,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiAnimationPlayOutline,
  mdiApi,
  mdiArchiveArrowDownOutline,
  mdiArrowCollapseAll,
  mdiArrowCollapseUp,
  mdiArrowDown,
  mdiArrowExpandAll,
  mdiArrowExpandHorizontal,
  mdiArrowLeft,
  mdiArrowUp,
  mdiAt,
  mdiAutorenew,
  mdiBackburger,
  mdiBallot,
  mdiBallotOutline,
  mdiBookOpenPageVariant,
  mdiBookOpenVariant,
  mdiBookmarkMultipleOutline,
  mdiBookmarkOutline,
  mdiBorderColor,
  mdiChartBar,
  mdiChartBox,
  mdiChartPie,
  mdiCalculator,
  mdiCalendar,
  mdiCalendarAccountOutline,
  mdiCalendarCheck,
  mdiCalendarMonthOutline,
  mdiCalendarAlert,
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
  mdiCalendarBlank,
  mdiCalendarClock,
  mdiCalendarOutline,
  mdiCalendarQuestion,
  mdiCalendarRange,
  mdiCalendarSync,
  mdiCameraIris,
  mdiCancel,
  mdiCarCruiseControl,
  mdiCardAccountDetailsOutline,
  mdiCardBulletedOutline,
  mdiCellphone,
  mdiCellphoneCheck,
  mdiCertificateOutline,
  mdiCheck,
  mdiCheckCircle,
  mdiCheckCircleOutline,
  mdiCheckbook,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedCircleAutoOutline,
  mdiCheckboxMarkedOutline,
  mdiCheckboxMultipleMarkedOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronTripleRight,
  mdiChevronUp,
  mdiCircle,
  mdiCircleOutline,
  mdiCityVariant,
  mdiClipboardListOutline,
  mdiClipboardText,
  mdiClockOutline,
  mdiClockPlusOutline,
  mdiClockRemoveOutline,
  mdiClockStart,
  mdiClose,
  mdiCloseCircleOutline,
  mdiCloseOutline,
  mdiCloudSearchOutline,
  mdiCloudUploadOutline,
  mdiCloudSyncOutline,
  mdiCloudDownload,
  mdiCloudOffOutline,
  mdiCog,
  mdiCogOutline,
  mdiCollapseAll,
  mdiCommentPlus,
  mdiContentCopy,
  mdiContentSave,
  mdiCrosshairs,
  mdiCrosshairsGps,
  mdiCurrencyEur,
  mdiDatabaseArrowDownOutline,
  mdiDatabaseImportOutline,
  mdiDatabaseOutline,
  mdiDelete,
  mdiDeleteOutline,
  mdiDeleteSweepOutline,
  mdiDivision,
  mdiDoctor,
  mdiDomain,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiDownload,
  mdiEmail,
  mdiEmailCheck,
  mdiEmailFast,
  mdiEmailFastOutline,
  mdiEmailOffOutline,
  mdiEmailOpenMultipleOutline,
  mdiEmailOpenOutline,
  mdiEmailOutline,
  mdiEmailPlus,
  mdiEqual,
  mdiEqualizer,
  //mdiEventBlankOutline,
  mdiEraser,
  mdiExitToApp,
  mdiExpandAllOutline,
  mdiEye,
  mdiEyeLockOpenOutline,
  mdiEyeLockOutline,
  mdiEyeOff,
  mdiFaceMan,
  mdiFactory,
  mdiFileChart,
  mdiFileChartOutline,
  mdiFileCheck,
  mdiFileCertificateOutline,
  mdiFileCompare,
  mdiFileDocument,
  mdiFileDocumentMultipleOutline,
  mdiFileDocumentOutline,
  mdiFileDocumentRemoveOutline,
  mdiFileExcel,
  mdiFileExcelBoxOutline,
  mdiFileImportOutline,
  mdiFileImageMarkerOutline,
  mdiFileMultipleOutline,
  mdiFileOutline,
  mdiFilePowerpoint,
  mdiFileQuestionOutline,
  mdiFileReplaceOutline,
  mdiFileSwapOutline,
  mdiFileTree,
  mdiFileUpload,
  mdiFileWord,
  mdiFilterOutline,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiFinance,
  mdiFlash,
  mdiFolderAccountOutline,
  mdiFolderMove,
  mdiFolderOutline,
  mdiFolderPlusOutline,
  mdiForklift,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatIndentIncrease,
  mdiFormatListBulleted,
  mdiFormatListBulletedType,
  mdiFormatListCheckbox,
  mdiFormatListChecks,
  mdiFormatListNumbered,
  mdiFormatListText,
  mdiFormatPageBreak,
  mdiFormatSection,
  mdiGoogleMaps,
  mdiGrid,
  mdiGroup,
  mdiHeartMultipleOutline,
  mdiHeartOutline,
  mdiHeartRemoveOutline,
  mdiHazardLights,
  mdiHelpCircleOutline,
  mdiHistory,
  mdiHome,
  mdiHomeCity,
  mdiHomeCityOutline,
  mdiHomeOutline,
  mdiHospital,
  mdiImage,
  mdiImageMarkerOutline,
  //mdiImageFilter,
  mdiImageMultipleOutline,
  mdiImageOff,
  mdiImageOutline,
  mdiImageSizeSelectLarge,
  mdiImageText,
  mdiImageRemoveOutline,
  mdiInformationOutline,
  mdiKey,
  mdiLabel,
  mdiLabelOutline,
  mdiLinkVariant,
  mdiListStatus,
  mdiLock,
  mdiLockOpen,
  mdiLockReset,
  mdiLogin,
  mdiMagnify,
  mdiMap,
  mdiMapLegend,
  mdiMapMarkerCheck,
  mdiMapMarkerMultipleOutline,
  mdiMapMarkerOutline,
  mdiMapMarkerPlus,
  mdiMapMarkerQuestion,
  mdiMapMarkerRadius,
  mdiMapMarkerRemoveVariant,
  mdiMapMarkerRemove,
  mdiMapOutline,
  mdiMenuDown,
  mdiMenuUp,
  mdiMerge,
  mdiMirrorRectangle,
  mdiMonitorCellphone,
  mdiMultiplication,
  mdiNewBox,
  mdiNoteOutline,
  mdiNumeric,
  mdiNumeric0,
  mdiNumeric1,
  mdiNumeric2,
  mdiNumeric3,
  mdiNumeric4,
  mdiNumeric5,
  mdiNumeric6,
  mdiNumeric7,
  mdiNumeric8,
  mdiNumeric9,
  mdiOdnoklassniki,
  mdiOfficeBuilding,
  mdiOfficeBuildingMarker,
  mdiOfficeBuildingMarkerOutline,
  mdiOpenInNew,
  mdiPageLayoutFooter,
  mdiPageLayoutHeader,
  mdiPalette,
  mdiPaperclipPlus,
  mdiPencil,
  mdiPencilOff,
  mdiPencilBoxMultipleOutline,
  mdiPhone,
  mdiPhoneOutline,
  mdiPierCrane,
  mdiPinOffOutline,
  mdiPinOutline,
  mdiPineTree,
  mdiPlaylistEdit,
  mdiPlaylistPlus,
  mdiPlaylistRemove,
  mdiPlaylistStar,
  mdiPlus,
  mdiPoll,
  mdiPound,
  mdiPublish,
  mdiPublishOff,
  mdiQrcodePlus,
  mdiRadioboxBlank,
  mdiRefresh,
  mdiRepeat,
  mdiRotateLeft,
  mdiRotateRight,
  mdiSafetyGoggles,
  mdiScaleBalance,
  mdiSchool,
  mdiSchoolOutline,
  mdiScreenRotation,
  mdiSelectMarker,
  mdiShapeOutline,
  mdiShareVariantOutline,
  mdiSignatureFreehand,
  mdiSignatureImage,
  mdiSignText,
  mdiSort,
  mdiStar,
  mdiStarOutline,
  mdiStarPlusOutline,
  mdiStarRemoveOutline,
  mdiSwapHorizontalBold,
  mdiSwapVerticalBold,
  mdiSync,
  mdiTable,
  mdiTableOfContents,
  mdiTablePlus,
  mdiText,
  mdiThumbDown,
  mdiThumbUp,
  mdiTicketAccount,
  mdiTimerSand,
  mdiTimerSandEmpty,
  mdiTractor,
  mdiTranslate,
  mdiTrendingUp,
  mdiTwoFactorAuthentication,
  mdiUndo,
  mdiUpload,
  mdiVectorArrangeAbove,
  mdiViewComfy,
  mdiViewDashboardOutline,
  mdiViewDashboardEditOutline,
  mdiViewList,
  mdiWalletOutline,
  mdiWeb,
} from '@mdi/js'

//Wurde in MaterialDesignIcons 6.x entfernt
const pdfAcrobatIcon = 'M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M10.1,11.4C10.08,11.44 9.81,13.16 8,16.09C8,16.09 4.5,17.91 5.33,19.27C6,20.35 7.65,19.23 9.07,16.59C9.07,16.59 10.89,15.95 13.31,15.77C13.31,15.77 17.17,17.5 17.7,15.66C18.22,13.8 14.64,14.22 14,14.41C14,14.41 12,13.06 11.5,11.2C11.5,11.2 12.64,7.25 10.89,7.3C9.14,7.35 9.8,10.43 10.1,11.4M10.91,12.44C10.94,12.45 11.38,13.65 12.8,14.9C12.8,14.9 10.47,15.36 9.41,15.8C9.41,15.8 10.41,14.07 10.91,12.44M14.84,15.16C15.42,15 17.17,15.31 17.1,15.64C17.04,15.97 14.84,15.16 14.84,15.16M7.77,17C7.24,18.24 6.33,19 6.1,19C5.87,19 6.8,17.4 7.77,17M10.91,10.07C10.91,10 10.55,7.87 10.91,7.92C11.45,8 10.91,10 10.91,10.07Z'

const icons = {
  'mdi-account': mdiAccount,
  'mdi-account-arrow-right-outline': mdiAccountArrowRightOutline,
  'mdi-account-box-multiple-outline': mdiAccountBoxMultipleOutline,
  'mdi-account-check': mdiAccountCheck,
  'mdi-account-circle': mdiAccountCircle,
  'mdi-account-group-outline': mdiAccountGroupOutline,
  'mdi-account-hard-hat': mdiAccountHardHat,
  'mdi-account-key-outline': mdiAccountKeyOutline,
  'mdi-account-minus-outline': mdiAccountMinusOutline,
  'mdi-account-multiple': mdiAccountMultiple,
  'mdi-account-multiple-outline': mdiAccountMultipleOutline,
  'mdi-account-multiple-remove-outline': mdiAccountMultipleRemoveOutline,
  'mdi-account-outline': mdiAccountOutline,
  'mdi-account-plus-outline': mdiAccountPlusOutline,
  'mdi-account-remove-outline': mdiAccountRemoveOutline,
  'mdi-account-star-outline': mdiAccountStarOutline,
  'mdi-account-supervisor-outline': mdiAccountSupervisorOutline,
  'mdi-account-tie-outline': mdiAccountTieOutline,
  'mdi-alert-circle': mdiAlertCircle,
  'mdi-alert-circle-outline': mdiAlertCircleOutline,
  'mdi-alert-outline': mdiAlertOutline,
  'mdi-align-vertical-bottom': mdiAlignVerticalBottom,
  'mdi-animation-play-outline': mdiAnimationPlayOutline,
  'mdi-api': mdiApi,
  'mdi-archive-arrow-down-outline': mdiArchiveArrowDownOutline,
  'mdi-arrow-collapse-all': mdiArrowCollapseAll,
  'mdi-arrow-collapse-up': mdiArrowCollapseUp,
  'mdi-arrow-down': mdiArrowDown,
  'mdi-arrow-expand-all': mdiArrowExpandAll,
  'mdi-arrow-expand-horizontal': mdiArrowExpandHorizontal,
  'mdi-arrow-left': mdiArrowLeft,
  'mdi-arrow-up': mdiArrowUp,
  'mdi-at': mdiAt,
  'mdi-autorenew': mdiAutorenew,
  'mdi-backburger': mdiBackburger,
  'mdi-ballot': mdiBallot,
  'mdi-ballot-outline': mdiBallotOutline,
  'mdi-book-open-page-variant': mdiBookOpenPageVariant,
  'mdi-book-open-variant': mdiBookOpenVariant,
  'mdi-bookmark-multiple-outline': mdiBookmarkMultipleOutline,
  'mdi-bookmark-outline': mdiBookmarkOutline,
  'mdi-border-color': mdiBorderColor,
  'mdi-calculator': mdiCalculator,
  'mdi-calendar': mdiCalendar,
  'mdi-calendar-account-outline': mdiCalendarAccountOutline,
  'mdi-calendar-alert': mdiCalendarAlert,
  'mdi-calendar-arrow-left': mdiCalendarArrowLeft,
  'mdi-calendar-arrow-right': mdiCalendarArrowRight,
  'mdi-calendar-blank': mdiCalendarBlank,
  'mdi-calendar-check': mdiCalendarCheck,
  'mdi-calendar-clock': mdiCalendarClock,
  'mdi-calendar-month-outline': mdiCalendarMonthOutline,
  'mdi-calendar-outline': mdiCalendarOutline,
  'mdi-calendar-question': mdiCalendarQuestion,
  'mdi-calendar-range': mdiCalendarRange,
  'mdi-calendar-sync': mdiCalendarSync,
  'mdi-camera-iris': mdiCameraIris,
  'mdi-cancel': mdiCancel,
  'mdi-car-cruise-control': mdiCarCruiseControl,
  'mdi-card-account-details-outline': mdiCardAccountDetailsOutline,
  'mdi-card-bulleted-outline': mdiCardBulletedOutline,
  'mdi-cellphone': mdiCellphone,
  'mdi-cellphone-check': mdiCellphoneCheck,
  'mdi-certificate-outline': mdiCertificateOutline,
  'mdi-chart-bar': mdiChartBar,
  'mdi-chart-box': mdiChartBox,
  'mdi-chart-pie': mdiChartPie,
  'mdi-check': mdiCheck,
  'mdi-check-circle': mdiCheckCircle,
  'mdi-check-circle-outline': mdiCheckCircleOutline,
  'mdi-checkbook': mdiCheckbook,
  'mdi-checkbox-blank-outline': mdiCheckboxBlankOutline,
  'mdi-checkbox-marked-circle-auto-outline': mdiCheckboxMarkedCircleAutoOutline,
  'mdi-checkbox-marked-outline': mdiCheckboxMarkedOutline,
  'mdi-checkbox-multiple-marked-outline': mdiCheckboxMultipleMarkedOutline,
  'mdi-chevron-down': mdiChevronDown,
  'mdi-chevron-left': mdiChevronLeft,
  'mdi-chevron-right': mdiChevronRight,
  'mdi-chevron-triple-right': mdiChevronTripleRight,
  'mdi-chevron-up': mdiChevronUp,
  'mdi-circle': mdiCircle,
  'mdi-circle-outline': mdiCircleOutline,
  'mdi-city-variant': mdiCityVariant,
  'mdi-clipboard-list-outline': mdiClipboardListOutline,
  'mdi-clipboard-text': mdiClipboardText,
  'mdi-clock-outline': mdiClockOutline,
  'mdi-clock-plus-outline': mdiClockPlusOutline,
  'mdi-clock-remove-outline': mdiClockRemoveOutline,
  'mdi-clock-start': mdiClockStart,
  'mdi-close': mdiClose,
  'mdi-close-circle-outline': mdiCloseCircleOutline,
  'mdi-close-outline': mdiCloseOutline,
  'mdi-cloud-download': mdiCloudDownload,
  'mdi-cloud-off-outline': mdiCloudOffOutline,
  'mdi-cloud-search-outline': mdiCloudSearchOutline,
  'mdi-cloud-sync-outline': mdiCloudSyncOutline,
  'mdi-cloud-upload-outline': mdiCloudUploadOutline,
  'mdi-cog': mdiCog,
  'mdi-cog-outline': mdiCogOutline,
  'mdi-collapse-all': mdiCollapseAll,
  'mdi-comment-plus': mdiCommentPlus,
  'mdi-content-copy': mdiContentCopy,
  'mdi-content-save': mdiContentSave,
  'mdi-crosshairs': mdiCrosshairs,
  'mdi-crosshairs-gps': mdiCrosshairsGps,
  'mdi-currency-eur': mdiCurrencyEur,
  'mdi-database-arrow-down-outline': mdiDatabaseArrowDownOutline,
  'mdi-database-import-outline': mdiDatabaseImportOutline,
  'mdi-database-outline': mdiDatabaseOutline,
  'mdi-delete': mdiDelete,
  'mdi-delete-outline': mdiDeleteOutline,
  'mdi-delete-sweep-outline': mdiDeleteSweepOutline,
  'mdi-division': mdiDivision,
  'mdi-doctor': mdiDoctor,
  'mdi-domain': mdiDomain,
  'mdi-dots-horizontal': mdiDotsHorizontal,
  'mdi-dots-vertical': mdiDotsVertical,
  'mdi-download': mdiDownload,
  'mdi-email': mdiEmail,
  'mdi-email-check': mdiEmailCheck,
  'mdi-email-fast': mdiEmailFast,
  'mdi-email-fast-outline': mdiEmailFastOutline,
  'mdi-email-off-outline': mdiEmailOffOutline,
  'mdi-email-open-multiple-outline': mdiEmailOpenMultipleOutline,
  'mdi-email-open-outline': mdiEmailOpenOutline,
  'mdi-email-outline': mdiEmailOutline,
  'mdi-email-plus': mdiEmailPlus,
  'mdi-equal': mdiEqual,
  'mdi-equalizer': mdiEqualizer,
  'mdi-eraser': mdiEraser,
  //'mdi-event-blank-outline': mdiEventBlankOutline,
  'mdi-exit-to-app': mdiExitToApp,
  'mdi-expand-all-outline': mdiExpandAllOutline,
  'mdi-eye': mdiEye,
  'mdi-eye-lock-open-outline': mdiEyeLockOpenOutline,
  'mdi-eye-lock-outline': mdiEyeLockOutline,
  'mdi-eye-off': mdiEyeOff,
  'mdi-face-man': mdiFaceMan,
  'mdi-factory': mdiFactory,
  'mdi-file-chart': mdiFileChart,
  'mdi-file-chart-outline': mdiFileChartOutline,
  'mdi-file-check': mdiFileCheck,
  'mdi-file-certificate-outline': mdiFileCertificateOutline,
  'mdi-file-compare': mdiFileCompare,
  'mdi-file-document': mdiFileDocument,
  'mdi-file-document-multiple-outline': mdiFileDocumentMultipleOutline,
  'mdi-file-document-outline': mdiFileDocumentOutline,
  'mdi-file-document-remove-outline': mdiFileDocumentRemoveOutline,
  'mdi-file-excel': mdiFileExcel,
  'mdi-file-excel-box-outline': mdiFileExcelBoxOutline,
  'mdi-file-import-outline': mdiFileImportOutline,
  'mdi-file-image-marker-outline': mdiFileImageMarkerOutline,
  'mdi-file-multiple-outline': mdiFileMultipleOutline,
  'mdi-file-outline': mdiFileOutline,
  'mdi-file-pdf': pdfAcrobatIcon,
  'mdi-file-powerpoint': mdiFilePowerpoint,
  'mdi-file-question-outline': mdiFileQuestionOutline,
  'mdi-file-replace-outline': mdiFileReplaceOutline,
  'mdi-file-swap-outline': mdiFileSwapOutline,
  'mdi-file-tree': mdiFileTree,
  'mdi-file-upload': mdiFileUpload,
  'mdi-file-word': mdiFileWord,
  'mdi-filter-outline': mdiFilterOutline,
  'mdi-filter-variant': mdiFilterVariant,
  'mdi-filter-variant-remove': mdiFilterVariantRemove,
  'mdi-finance': mdiFinance,
  'mdi-flash': mdiFlash,
  'mdi-folder-account-outline': mdiFolderAccountOutline,
  'mdi-folder-move': mdiFolderMove,
  'mdi-folder-outline': mdiFolderOutline,
  'mdi-folder-plus-outline': mdiFolderPlusOutline,
  'mdi-forklift': mdiForklift,
  'mdi-format-header-1': mdiFormatHeader1,
  'mdi-format-header-2': mdiFormatHeader2,
  'mdi-format-header-3': mdiFormatHeader3,
  'mdi-format-indent-increase': mdiFormatIndentIncrease,
  'mdi-format-list-bulleted': mdiFormatListBulleted,
  'mdi-format-list-bulleted-type': mdiFormatListBulletedType,
  'mdi-format-list-checkbox': mdiFormatListCheckbox,
  'mdi-format-list-checks': mdiFormatListChecks,
  'mdi-format-list-numbered': mdiFormatListNumbered,
  'mdi-format-list-text': mdiFormatListText,
  'mdi-format-page-break': mdiFormatPageBreak,
  'mdi-format-section': mdiFormatSection,
  'mdi-google-maps': mdiGoogleMaps,
  'mdi-grid': mdiGrid,
  'mdi-group': mdiGroup,
  'mdi-hazard-lights': mdiHazardLights,
  'mdi-heart-multiple-outline': mdiHeartMultipleOutline,
  'mdi-heart-outline': mdiHeartOutline,
  'mdi-heart-remove-outline': mdiHeartRemoveOutline,
  'mdi-help-circle-outline': mdiHelpCircleOutline,
  'mdi-history': mdiHistory,
  'mdi-home': mdiHome,
  'mdi-home-city': mdiHomeCity,
  'mdi-home-city-outline': mdiHomeCityOutline,
  'mdi-home-outline': mdiHomeOutline,
  'mdi-hospital': mdiHospital,
  'mdi-image': mdiImage,
  'mdi-image-marker-outline': mdiImageMarkerOutline,
  //'mdi-image-filter': mdiImageFilter,
  'mdi-image-multiple-outline': mdiImageMultipleOutline,
  'mdi-image-off': mdiImageOff,
  'mdi-image-outline': mdiImageOutline,
  'mdi-image-size-select-large': mdiImageSizeSelectLarge,
  'mdi-image-text': mdiImageText,
  'mdi-image-remove-outline': mdiImageRemoveOutline,
  'mdi-information-outline': mdiInformationOutline,
  'mdi-key': mdiKey,
  'mdi-label': mdiLabel,
  'mdi-label-outline': mdiLabelOutline,
  'mdi-link-variant': mdiLinkVariant,
  'mdi-list-status': mdiListStatus,
  'mdi-lock': mdiLock,
  'mdi-lock-open': mdiLockOpen,
  'mdi-lock-reset': mdiLockReset,
  'mdi-login': mdiLogin,
  'mdi-magnify': mdiMagnify,
  'mdi-map': mdiMap,
  'mdi-map-legend': mdiMapLegend,
  'mdi-map-marker-check': mdiMapMarkerCheck,
  'mdi-map-marker-multiple-outline': mdiMapMarkerMultipleOutline,
  'mdi-map-marker-outline': mdiMapMarkerOutline,
  'mdi-map-marker-plus': mdiMapMarkerPlus,
  'mdi-map-marker-question': mdiMapMarkerQuestion,
  'mdi-map-marker-radius': mdiMapMarkerRadius,
  'mdi-map-marker-remove': mdiMapMarkerRemove,
  'mdi-map-marker-remove-variant': mdiMapMarkerRemoveVariant,
  'mdi-map-outline': mdiMapOutline,
  'mdi-menu-down': mdiMenuDown,
  'mdi-menu-up': mdiMenuUp,
  'mdi-merge': mdiMerge,
  'mdi-mirror-rectangle': mdiMirrorRectangle,
  'mdi-monitor-cellphone': mdiMonitorCellphone,
  'mdi-multiplication': mdiMultiplication,
  'mdi-new-box': mdiNewBox,
  'mdi-note-outline': mdiNoteOutline,
  'mdi-numeric': mdiNumeric,
  'mdi-numeric-0': mdiNumeric0,
  'mdi-numeric-1': mdiNumeric1,
  'mdi-numeric-2': mdiNumeric2,
  'mdi-numeric-3': mdiNumeric3,
  'mdi-numeric-4': mdiNumeric4,
  'mdi-numeric-5': mdiNumeric5,
  'mdi-numeric-6': mdiNumeric6,
  'mdi-numeric-7': mdiNumeric7,
  'mdi-numeric-8': mdiNumeric8,
  'mdi-numeric-9': mdiNumeric9,
  'mdi-odnoklassniki': mdiOdnoklassniki,
  'mdi-office-building': mdiOfficeBuilding,
  'mdi-office-building-marker': mdiOfficeBuildingMarker,
  'mdi-office-building-marker-outline': mdiOfficeBuildingMarkerOutline,
  'mdi-open-in-new': mdiOpenInNew,
  'mdi-page-layout-footer': mdiPageLayoutFooter,
  'mdi-page-layout-header': mdiPageLayoutHeader,
  'mdi-palette': mdiPalette,
  'mdi-paperclip-plus': mdiPaperclipPlus,
  'mdi-pencil': mdiPencil,
  'mdi-pencil-off': mdiPencilOff,
  'mdi-pencil-box-multiple-outline': mdiPencilBoxMultipleOutline,
  'mdi-phone': mdiPhone,
  'mdi-phone-outline': mdiPhoneOutline,
  'mdi-pier-crane': mdiPierCrane,
  'mdi-pin-off-outline': mdiPinOffOutline,
  'mdi-pin-outline': mdiPinOutline,
  'mdi-pine-tree': mdiPineTree,
  'mdi-playlist-edit': mdiPlaylistEdit,
  'mdi-playlist-plus': mdiPlaylistPlus,
  'mdi-playlist-remove': mdiPlaylistRemove,
  'mdi-playlist-star': mdiPlaylistStar,
  'mdi-plus': mdiPlus,
  'mdi-poll': mdiPoll,
  'mdi-pound': mdiPound,
  'mdi-publish': mdiPublish,
  'mdi-publish-off': mdiPublishOff,
  'mdi-qrcode-plus': mdiQrcodePlus,
  'mdi-radiobox-blank': mdiRadioboxBlank,
  'mdi-refresh': mdiRefresh,
  'mdi-repeat': mdiRepeat,
  'mdi-rotate-left': mdiRotateLeft,
  'mdi-rotate-right': mdiRotateRight,
  'mdi-safety-goggles': mdiSafetyGoggles,
  'mdi-scale-balance': mdiScaleBalance,
  'mdi-school': mdiSchool,
  'mdi-school-outline': mdiSchoolOutline,
  'mdi-screen-rotation': mdiScreenRotation,
  'mdi-select-marker': mdiSelectMarker,
  'mdi-shape-outline': mdiShapeOutline,
  'mdi-share-variant-outline': mdiShareVariantOutline,
  'mdi-sign-text': mdiSignText,
  'mdi-signature-freehand': mdiSignatureFreehand,
  'mdi-signature-image': mdiSignatureImage,
  'mdi-sort': mdiSort,
  'mdi-star': mdiStar,
  'mdi-star-outline': mdiStarOutline,
  'mdi-star-plus-outline': mdiStarPlusOutline,
  'mdi-star-remove-outline': mdiStarRemoveOutline,
  'mdi-swap-horizontal-bold': mdiSwapHorizontalBold,
  'mdi-swap-vertical-bold': mdiSwapVerticalBold,
  'mdi-sync': mdiSync,
  'mdi-table': mdiTable,
  'mdi-table-of-contents': mdiTableOfContents,
  'mdi-table-plus': mdiTablePlus,
  'mdi-text': mdiText,
  'mdi-thumb-down': mdiThumbDown,
  'mdi-thumb-up': mdiThumbUp,
  'mdi-ticket-account': mdiTicketAccount,
  'mdi-timer-sand': mdiTimerSand,
  'mdi-timer-sand-empty': mdiTimerSandEmpty,
  'mdi-tractor': mdiTractor,
  'mdi-translate': mdiTranslate,
  'mdi-trending-up': mdiTrendingUp,
  'mdi-two-factor-authentication': mdiTwoFactorAuthentication,
  'mdi-undo': mdiUndo,
  'mdi-upload': mdiUpload,
  'mdi-vector-arrange-above': mdiVectorArrangeAbove,
  'mdi-view-comfy': mdiViewComfy,
  'mdi-view-list': mdiViewList,
  'mdi-view-dashboard-outline': mdiViewDashboardOutline,
  'mdi-view-dashboard-edit-outline': mdiViewDashboardEditOutline,
  'mdi-wallet-outline': mdiWalletOutline,
  'mdi-web': mdiWeb,
}

/** Type: Name eines der Icons die in AsiX verfügbar sind */
export type RegisteredIcon = keyof typeof icons

export default icons as Record<RegisteredIcon, string>