import { RouteRecordRaw } from 'vue-router'
import { MODULE_PRAEVENTION_COLOR } from '@/modules/moduleConstants'

// Praevention
const GefaehrdungenList = () => import('@/modules/praevention/GefaehrdungenList.vue')
const GefaehrdungenForm = () => import('@/modules/praevention/GefaehrdungenForm.vue')
const GruppenPraeventionList = () => import('@/modules/praevention/GruppenPraeventionList.vue')
const GruppenPraeventionForm = () => import('@/modules/praevention/GruppenPraeventionForm.vue')
const KategorienPraeventionList = () => import('@/modules/praevention/KategorienPraeventionList.vue')
const KategorienPraeventionForm = () => import('@/modules/praevention/KategorienPraeventionForm.vue')
const GefahrstoffeForm = () => import('@/modules/praevention/GefahrstoffeForm.vue')
const GefahrstoffeList = () => import('@/modules/praevention/GefahrstoffeList.vue')
const HerstellerPraeventionForm = () => import('@/modules/praevention/HerstellerPraeventionForm.vue')
const HerstellerPraeventionList = () => import('@/modules/praevention/HerstellerPraeventionList.vue')
const VerwendungszweckList = () => import('@/modules/praevention/VerwendungszweckList.vue')
const VerwendungszweckForm = () => import('@/modules/praevention/VerwendungszweckForm.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

const routes: RouteRecordRaw[] = [
  // Gefaehrdung
  { path: '/praevention/gefaehrdung/',
    name: 'GefaehrdungenList',
    component: GefaehrdungenList,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefaehrdung/add',
    name: 'GefaehrdungenForm',
    component: GefaehrdungenForm,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefaehrdung/:id',
    name: 'GefaehrdungenFormUpdate',
    component: GefaehrdungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-hazard-lights', color: MODULE_PRAEVENTION_COLOR }
  },
  // Gruppen (Praevention)
  { path: '/praevention/gruppen/',
    name: 'GruppenPraeventionList',
    component: GruppenPraeventionList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gruppen/add',
    name: 'GruppenPraeventionForm',
    component: GruppenPraeventionForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gruppen/:id',
    name: 'GruppenPraeventionFormUpdate',
    component: GruppenPraeventionForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Kategorien (Praevention)
  { path: '/praevention/kategorien/',
    name: 'KategorienPraeventionList',
    component: KategorienPraeventionList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/kategorien/add',
    name: 'KategorienPraeventionForm',
    component: KategorienPraeventionForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/kategorien/:id',
    name: 'KategorienPraeventionFormUpdate',
    component: KategorienPraeventionForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Gefahrstoffe
  { path: '/praevention/gefahrstoffe/',
    name: 'GefahrstoffeList',
    component: GefahrstoffeList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefahrstoffe/add',
    name: 'GefahrstoffeForm',
    component: GefahrstoffeForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/gefahrstoffe/:id',
    name: 'GefahrstoffeFormUpdate',
    component: GefahrstoffeForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  // Hersteller (Praevention)
  { path: '/praevention/hersteller/',
    name: 'HerstellerPraeventionList',
    component: HerstellerPraeventionList,
    meta: { icon: 'mdi-factory', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/hersteller/add',
    name: 'HerstellerPraeventionForm',
    component: HerstellerPraeventionForm,
    meta: { icon: 'mdi-factory', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/hersteller/:id',
    name: 'HerstellerPraeventionFormUpdate',
    component: HerstellerPraeventionForm,
    props: updateFormProps,
    meta: { icon: 'mdi-factory', color: MODULE_PRAEVENTION_COLOR }
  },
  // Verwendungszweck (Praevention)
  { path: '/praevention/verwendungszwecke/',
    name: 'VerwendungszewckeList',
    component: VerwendungszweckList,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/verwendungszwecke/add',
    name: 'VerwendungszweckForm',
    component: VerwendungszweckForm,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
  { path: '/praevention/verwendungszwecke/:id',
    name: 'VerwendungszweckFormUpdate',
    component: VerwendungszweckForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_PRAEVENTION_COLOR }
  },
]

export default routes
