export const MODULE_ARBEITSMITTEL_COLOR = '#c9a470'
export const MODULE_ANALYSEN_COLOR = '#c583b9'
export const MODULE_AUFGABEN_COLOR = '#1976d2'
export const MODULE_MANAGEMENT_COLOR = '#de0613'
export const MODULE_MITARBEITER_COLOR = '#ef8a4b'
export const MODULE_NOTIZEN_COLOR = MODULE_AUFGABEN_COLOR
export const MODULE_ORGANISATION_COLOR = '#00943f'
export const MODULE_PRAEVENTION_COLOR = MODULE_ANALYSEN_COLOR
export const MODULE_PROJEKTE_COLOR = '#1c5d98'
export const MODULE_STAMMDATEN_COLOR = MODULE_AUFGABEN_COLOR
export const MODULE_VERWALTUNG_COLOR = MODULE_AUFGABEN_COLOR
export const MODULE_ADMINISTRATION_COLOR = MODULE_AUFGABEN_COLOR
export const MODULE_LIEGENSCHAFTEN_COLOR = '#00838f'
export const MODULE_TICKETSYSTEM_COLOR = MODULE_AUFGABEN_COLOR
export const MODULE_BERICHTE_COLOR = MODULE_AUFGABEN_COLOR
export const MODULE_SCHULUNGSORGANISATION_COLOR = '#00796B'

export const MODULE_ORGANISATION = 1
export const MODULE_MANAGEMENT = 2
export const MODULE_MITARBEITER = 3
export const MODULE_ARBEITSMITTEL = 4
export const MODULE_PRAEVENTION = 5
export const MODULE_PROJEKTE = 6
export const MODULE_AUFGABEN = 7
export const MODULE_LIEGENSCHAFTEN = 8
export const MODULE_TICKETSYSTEM = 9
export const MODULE_HISTORY = 10
export const MODULE_SCHULUNGSORGANISATION = 11
export const MODULE_ANALYSEN = 12
export const MODULE_SIGNATURES = 13
export const MODULE_EMAILING = 14

// Globale Action Positionen
export const ADD_ACTION_ORDER = 0
export const SAVE_ACTION_ORDER = 1
export const PROZESS_ACTION_ORDER = 6
export const ANALYSE_ACTION_ORDER = 7
export const FULLSCREEN_ACTION_ORDER= 8
export const REFRESH_ACTION_ORDER = 9
export const FILTER_ACTION_ORDER = 10
export const SHOW_HISTORY_ACTION_ORDER = 14
export const DUPLICATE_ACTION_ORDER = 15
export const SHOW_DELETED_ACTION_ORDER = 16
export const DATENFILTER_INFO_ACTION_ORDER = 17
export const REPORT_ACTION_ORDER = 19
export const DEL_ACTION_ORDER = 20
export const SHOW_MAP_ORDER = 21
export const EXCELEXPORT_ACTION_ORDER = 22
export const EXCELEXPORT_ANSPRECHPERSONEN_ACTION_ORDER = 23
export const EXCELEXPORT_CHECKLISTEN_ACTION_ORDER = 23
export const EXCELEXPORT_KURSE_HOPPE_ACTION_ORDER = 23
export const EXCELEXPORT_PERSONEN_WINASPED_ACTION_ORDER = 23
export const QR_CODE_ACTION_ORDER = 24
export const RECORD_TRANSLATION_ACTION_ORDER = 25
export const SIGNATUREN_EINHOLEN_ACTION_ORDER = 26
export const FAVORITEN_HINZUFUEGEN_ACTION_ORDER = 27
export const PROJEKTE_IMPORT_ACTION_ORDER = 28

// Verwaltung Benutzer
export const HISTORY_ACTION_OF_USER = 4
export const EMAILBENACHRICHTIGUNG_TYP_AUFGABE = 0

// Aufgaben
export const AUFGABEN_ACTION_ABSCHLIESSEN = 4
export const AUFGABEN_ACTION_CHECKLISTE_HINZUFUEGEN = 6
export const AUFGABEN_ACTION_ABONNIEREN = 7

// Dokumente
export const DOKUMENTE_ACTION_NACHWEISDOKUMENTE = 4
export const DOKUMENTE_ACTION_VEROEFFENTLICHUNGEN = 5
export const DOKUMENTE_ACTION_VEROEFFENTLICHUNGEN_AENDERN = 6
export const DOKUMENTE_ACTION_BERICHT_ERSTELLEN = 6
export const DOKUMENTE_ACTION_BERICHT_AKTUALISIEREN = 7
export const DOKUMENTE_ACTION_SIGNIEREN = 8
export const DOKUMENTE_ACTION_DOWNLOAD = 9
export const DOKUMENTE_ACTION_DOWNLOAD_DOKUMENT_UND_ZERTIFIKATE = 10
export const DOKUMENTE_ACTION_DATENSAETZE_SPERREN = 11
export const DOKUMENTE_ACTION_DATENSAETZE_ENTSPERREN = 11
export const DOKUMENTE_ACTION_SIGNATURPROZESS_ERINNERUNGSMAIL = 12

// Kurse
export const KURSE_ACTION_AUSBILDUNGSNACHWEISE_GENERIEREN = 20

export let DOKUMENTETYP_AUSBILDUNGSNACHWEISE = localStorage.getItem('asixconfig_dokumentetyp_ausbildungsnachweise_id')

// Dokumente List
export const DOKUMENT_SPERREN_ACTION_ORDER = 6
export const DOKUMENT_ENTSPERREN_ACTION_ORDER = 7

// Arbeitsmittel
export const ARBEITSMITTEL_ACTION_ARBEITSMITTELUNTERLAGEN = 20

// Projekte
export const PROJEKTE_ACTION_PROJEKTUNTERLAGEN = 20

// Bilder
export const BILDER_ACTION_DOWNLOAD = 1
export const BILDER_ACTION_ROTATE_RIGHT = 2
export const BILDER_ACTION_ROTATE_LEFT = 3


// Bild Form
export const BILD_ACTION_LINKS_DREHEN = 3
export const BILD_ACTION_RECHTS_DREHEN = 4

// Schulungsorganisation
export const SCHULUNGSORGANISATION_ACTION_SCHULUNG_AUS_VORLAGE_ERSTELLEN = 1
export const SCHULUNGSORGANISATION_ACTION_VORLAGE_AUS_SCHULUNG_ERSTELLEN = 1
export const SCHULUNGSORGANISATION_ACTION_SCHULUNGSVORLAGE_AUSWAEHLEN = 1
export const SCHULUNGSORGANISATION_ACTION_SCHULUNGSDOKUMENTE_VEROEFFENTLICHEN = 1
export const SCHULUNGSORGANISATION_ACTION_TEILNEHMER_EINLADEN = 1
export const SCHULUNGSORGANISATION_ACTION_OEFFENTLICHE_SCHULUNGSSEITE_OEFFNEN = 1
export const SCHULUNGSORGANISATION_ACTION_SCHULUNGSKONTAKT_VEREINIGEN = 1
export const SCHULUNGSORGANISATION_ACTION_SCHULUNGEN_ABONNIEREN = 2
export const SCHULUNGSORGANISATION_ACTION_SCHULUNGSKONTAKT_ABMELDEN = 2
export const SCHULUNGSORGANISATION_ACTION_SCHULUNGSKONTAKT_LOESCHEN = 3
export const SCHULUNGSORGANISATION_ACTION_SCHULUNGSTEILNEHMER_VERSCHIEBEN = 4
export const SCHULUNGSORGANISATION_ACTION_BESTAETIGUNGSMAIL_SENDEN = 5
export const SCHULUNGSORGANISATION_ACTION_ERINNERUNGSMAIL_SENDEN = 6
export const SCHULUNGSORGANISATION_ACTION_EMAIL_MANUELL_SENDEN = 7
export const SCHULUNGSORGANISATION_ACTION_DIGITALES_ANWESENHEITSREGISTER = 22

// Organisation
export const ORGANISATION_HAUPTUNTERNEHMEN_SETZEN = 4
export let TAETIGKEITSBEREICH_ARBEITSMEDIZINER = localStorage.getItem('asixconfig_taetigkeitsbereich_arbeitsmediziner_id')
export let TAETIGKEITSBEREICH_SICHERHEITSKOORDINATOR = localStorage.getItem('asixconfig_taetigkeitsbereich_sicherheitskoordinator_id')
export let TAETIGKEITSBEREICH_LEITER_DER_DIENSTSTELLE_EXTERN = localStorage.getItem('asixconfig_taetigkeitsbereich_leiter_der_dienststelle_extern_id')

// Mitarbeiter
export const MITARBEITER_OBJEKTE_VERSCHIEBEN = 4

export let TAETIGKEIT_ARBEITGEBER = localStorage.getItem('asixconfig_taetigkeit_arbeitgeber_id')
export let TAETIGKEIT_LEITER_DER_DIENSTSTELLE = localStorage.getItem('asixconfig_taetigkeit_leiter_der_dienststelle_id')
export let TAETIGKEIT_SICHERHEITS_SPRECHER = localStorage.getItem('asixconfig_taetigkeit_sicherheits_sprecher_id')
export let TAETIGKEIT_SICHERHEITS_BEAUFTRAGTER =  localStorage.getItem('asixconfig_taetigkeit_sicherheits_beauftragter_id')
export let TAETIGKEIT_BRANDSCHUTZ_BEAUFTRAGTER = localStorage.getItem('asixconfig_taetigkeit_brandschutz_beauftragter_id')
export let TAETIGKEIT_ERSTE_HILFE_BEAUFTRAGTER = localStorage.getItem('asixconfig_taetigkeit_erste_hilfe_beauftragter_id')
export let TAETIGKEIT_BAUSTELLENLEITER = localStorage.getItem('asixconfig_taetigkeit_baustellenleiter_id')
export let TAETIGKEIT_VORARBEITER = localStorage.getItem('asixconfig_taetigkeit_vorarbeiter_id')

export const MATRIXMOBIL_MODUS_TAETIGKEITEN = 0  // index vom Modus-Array
export const MATRIXMOBIL_MODUS_QUALIFIKATIONEN = 1  // index vom Modus-Array
export const MATRIXMOBIL_MODUS_FIELDS = [
  [
    {'field': 'mitarbeiter', 'display_name': 'Mitarbeiter'},
    {'field': 'berufsbezeichnung', 'display_name': 'Tätigkeiten'}
  ],
  [
    {'field': 'mitarbeiter', 'display_name': 'Mitarbeiter'},
    {'field': 'qualifikation', 'display_name': 'Qualifizierungen'}
  ]
]

// Dashboard
export const SCHULUNGSPLANUNG_QUALIFIKATION = 1
export const SCHULUNGSPLANUNG_MITARBEITER = 2

export const MODULE = {
  'Allgemein': 0,
  'Organisation': MODULE_ORGANISATION,
  'Management': MODULE_MANAGEMENT,
  'Mitarbeiter': MODULE_MITARBEITER,
  'Arbeitsmittel': MODULE_ARBEITSMITTEL,
  'Praevention': MODULE_PRAEVENTION,
  'Projekte': MODULE_PROJEKTE,
  'Aufgaben': MODULE_AUFGABEN,
  'Liegenschaften': MODULE_LIEGENSCHAFTEN,
  'Schulungsoraganisation': MODULE_SCHULUNGSORGANISATION,
  'Analysen': MODULE_ANALYSEN,
}

export const TOOLTIP_OPEN_DELAY = 500

// Stammdaten
export let EMAILKATEGORIE_ARBEIT = localStorage.getItem('asixconfig_emailkategorie_arbeit_id')

export function reloadAsiXConfig () {
  DOKUMENTETYP_AUSBILDUNGSNACHWEISE = localStorage.getItem('asixconfig_dokumentetyp_ausbildungsnachweise_id')
  TAETIGKEITSBEREICH_ARBEITSMEDIZINER = localStorage.getItem('asixconfig_taetigkeitsbereich_arbeitsmediziner_id')
  TAETIGKEITSBEREICH_SICHERHEITSKOORDINATOR = localStorage.getItem('asixconfig_taetigkeitsbereich_sicherheitskoordinator_id')
  TAETIGKEITSBEREICH_LEITER_DER_DIENSTSTELLE_EXTERN = localStorage.getItem('asixconfig_taetigkeitsbereich_leiter_der_dienststelle_extern_id')
  TAETIGKEIT_ARBEITGEBER = localStorage.getItem('asixconfig_taetigkeit_arbeitgeber_id')
  TAETIGKEIT_LEITER_DER_DIENSTSTELLE = localStorage.getItem('asixconfig_taetigkeit_leiter_der_dienststelle_id')
  TAETIGKEIT_SICHERHEITS_SPRECHER = localStorage.getItem('asixconfig_taetigkeit_sicherheits_sprecher_id')
  TAETIGKEIT_SICHERHEITS_BEAUFTRAGTER =  localStorage.getItem('asixconfig_taetigkeit_sicherheits_beauftragter_id')
  TAETIGKEIT_BRANDSCHUTZ_BEAUFTRAGTER = localStorage.getItem('asixconfig_taetigkeit_brandschutz_beauftragter_id')
  TAETIGKEIT_ERSTE_HILFE_BEAUFTRAGTER = localStorage.getItem('asixconfig_taetigkeit_erste_hilfe_beauftragter_id')
  TAETIGKEIT_BAUSTELLENLEITER = localStorage.getItem('asixconfig_taetigkeit_baustellenleiter_id')
  TAETIGKEIT_VORARBEITER = localStorage.getItem('asixconfig_taetigkeit_vorarbeiter_id')
  EMAILKATEGORIE_ARBEIT = localStorage.getItem('asixconfig_emailkategorie_arbeit_id')
}

export const SCHULUNGSORGANISATION_RECHAPTCHA_PUBLIC_KEY = '6LfiA3EpAAAAAN8bCCSumJFHqifc5Lxk0yCvNkPI'