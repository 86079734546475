import { RouteRecordRaw } from 'vue-router'
import { MODULE_ANALYSEN_COLOR } from '@/modules/moduleConstants'

// Analysen
const BerechnungForm = () => import('@/modules/analysen/BerechnungForm.vue')
const AnalysenList = () => import('@/modules/analysen/AnalysenList.vue')
const AnalysenMap = () => import('@/modules/analysen/AnalysenMap.vue')
const AnalysenForm = () => import('@/modules/analysen/AnalysenForm.vue')
const AnalysenvorlagenList = () => import('@/modules/analysen/AnalysenvorlagenList.vue')
const AnalysenvorlagenForm = () => import('@/modules/analysen/AnalysenvorlagenForm.vue')
const AnforderungenList = () => import('@/modules/analysen/AnforderungenList.vue')
const AnforderungenForm = () => import('@/modules/analysen/AnforderungenForm.vue')
const AnforderungsdetailForm = () => import('@/modules/analysen/AnforderungsdetailForm.vue')
const AnalyseanforderungenList = () => import('@/modules/analysen/AnalyseanforderungenList.vue')
const AnalyseanforderungenForm = () => import('@/modules/analysen/AnalyseanforderungenForm.vue')
const BewertungsmethodeList = () => import('@/modules/analysen/BewertungsmethodeList.vue')
const BewertungsmethodeForm = () => import('@/modules/analysen/BewertungsmethodeForm.vue')
const BewertungForm = () => import('@/modules/analysen/BewertungForm.vue')
const BewertungsmatrixList = () => import('@/modules/analysen/BewertungsmatrixList.vue')
const BewertungsmatrixForm = () => import('@/modules/analysen/BewertungsmatrixForm.vue')
const EinflussfaktorForm = () => import('@/modules/analysen/EinflussfaktorForm.vue')
const EfwerteForm = () => import('@/modules/analysen/EfwerteForm.vue')
const AnalysenMatrix = () => import('@/modules/analysen/AnalysenMatrix.vue')
const BasePageNotFound = () => import('@/base/BasePageNotFound.vue')

const updateFormProps = (route) => {
  return {
    id: parseInt(route.params.id)
  }
}

// const updateFormObjectProps = (route) => {
//   return {
//     modul: route.params.modul,
//     model: route.params.model,
//     objectId: parseInt(route.params.objectId)
//   }
// }

const routes: RouteRecordRaw[] = [
  // Analysen
  { path: '/analysen/analysen/',
    name: 'AnalysenList',
    component: AnalysenList,
    meta: { icon: 'mdi-trending-up', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/analysen/map',
    name: 'AnalysenMap',
    component: AnalysenMap,
    meta: { icon: 'mdi-trending-up', color: MODULE_ANALYSEN_COLOR }
  },

  { path: '/analysen/analysen/add',
    name: 'AnalysenForm',
    component: AnalysenForm,
    meta: { icon: 'mdi-trending-up', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/analysen/:id',
    name: 'AnalysenFormUpdate',
    component: AnalysenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-trending-up', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/analyseanforderungen/',
    name: 'AnalysenObjektAnforderungenList',
    component: AnalyseanforderungenList,
    props: true,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/analysen/add',
    name: 'AnalysenObjektForm',
    component: AnalysenForm,
    props: true,
    meta: { icon: 'mdi-trending-up', color: MODULE_ANALYSEN_COLOR }
  },
  // // Analysen - Analyseanforderungen
  // { path: '/analysen/analyseanforderungen/add',
  //   name: 'AnalyseanforderungenForm',
  //   component: AnalyseanforderungenForm,
  //   meta: { icon: 'mdi-clipboard-text', color: MODULE_ANALYSEN_COLOR }
  // },
  { path: '/analysen/analyseanforderungen/:id',
    name: 'AnalyseanforderungenFormUpdate',
    component: AnalyseanforderungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/:modul/:model/:objectId/analyseanforderungen/add',
    name: 'AnalyseanforderungenObjektForm',
    component: AnalyseanforderungenForm,
    props: true,
    meta: { icon: 'mdi-clipboard-text', color: MODULE_ANALYSEN_COLOR }
  },
  // Analysen - Bewertungsmethode
  { path: '/analysen/bewertungsmethode/',
    name: 'BewertungsmethodeList',
    component: BewertungsmethodeList,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/bewertungsmethode/add',
    name: 'BewertungsmethodeForm',
    component: BewertungsmethodeForm,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/bewertungsmethode/:id',
    name: 'BewertungsmethodeFormUpdate',
    component: BewertungsmethodeForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  // Analysen - Bewertung
  { path: '/analysen/bewertungsmethode/:objectId/bewertungen/add',
    name: 'BewertungForm',
    component: BewertungForm,
    props: true,
    meta: { icon: 'mdi-chart-box', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/bewertung/:id',
    name: 'BewertungFormUpdate',
    component: BewertungForm,
    props: updateFormProps,
    meta: { icon: 'mdi-chart-box', color: MODULE_ANALYSEN_COLOR }
  },
  // Analysen - Bewertungsmatrix
  { path: '/analysen/bewertungsmatrix/',
    name: 'BewertungsmatrixList',
    component: BewertungsmatrixList,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/bewertungsmatrix/add',
    name: 'BewertungsmatrixForm',
    component: BewertungsmatrixForm,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/bewertungsmatrix/:id',
    name: 'BewertungsmatrixFormUpdate',
    component: BewertungsmatrixForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  //Analysenmatrix
  { path: '/analysen/analysenmatrix/',
    name: 'AnalysenMatrix',
    component: AnalysenMatrix,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_ANALYSEN_COLOR }
  },
  // Analysen - Berechnungen
  { path: '/analysen/bewertungsmatrix/:objectId/berechnungen/add',
    name: 'BerechnungForm',
    component: BerechnungForm,
    props: true,
    meta: { icon: 'mdi-chart-box', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/berechnung/:id',
    name: 'BerechnungFormUpdate',
    component: BerechnungForm,
    props: updateFormProps,
    meta: { icon: 'mdi-chart-box', color: MODULE_ANALYSEN_COLOR }
  },
  // Analysen - Einflussfaktor
  { path: '/analysen/bewertungsmatrix/:objectId/einflussfaktoren/add',
    name: 'EinflussfaktorForm',
    component: EinflussfaktorForm,
    props: true,
    meta: { icon: 'mdi-car-cruise-control', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/einflussfaktor/:id',
    name: 'EinflussfaktorFormUpdate',
    component: EinflussfaktorForm,
    props: updateFormProps,
    meta: { icon: 'mdi-car-cruise-control', color: MODULE_ANALYSEN_COLOR }
  },
  // Analysen - EinflussfaktorWerte
  { path: '/analysen/einflussfaktor/:objectId/efwerte/add',
    name: 'EfwerteForm',
    component: EfwerteForm,
    props: true,
    meta: { icon: 'mdi-numeric', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/efwerte/:id',
    name: 'EfwerteFormUpdate',
    component: EfwerteForm,
    props: updateFormProps,
    meta: { icon: 'mdi-numeric', color: MODULE_ANALYSEN_COLOR }
  },
  // Analysenvorlagen
  { path: '/analysen/analysenvorlagen/',
    name: 'AnalysenvorlagenList',
    component: AnalysenvorlagenList,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/analysenvorlagen/add',
    name: 'AnalysenvorlagenForm',
    component: AnalysenvorlagenForm,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/analysenvorlagen/:id',
    name: 'AnalysenvorlagenFormUpdate',
    component: AnalysenvorlagenForm,
    props: updateFormProps,
    meta: { icon: '', color: MODULE_ANALYSEN_COLOR }
  },
  // Anforderungen
  { path: '/analysen/anforderungen/',
    name: 'AnforderungenList',
    component: AnforderungenList,
    props: updateFormProps,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/anforderungen/:id',
    name: 'AnforderungenFormUpdate',
    component: AnforderungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/anforderungen/add',
    name: 'AnforderungenForm',
    component: AnforderungenForm,
    props: updateFormProps,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_ANALYSEN_COLOR }
  },
  // Anforderungsdetail
  { path: '/analysen/anforderungsdetail/:id',
    name: 'AnforderungsdetailFormUpdate',
    component: AnforderungsdetailForm,
    props: updateFormProps,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_ANALYSEN_COLOR }
  },
  { path: '/analysen/anforderungsdetail/add',
    name: 'AnforderungsdetailAdd',
    component: BasePageNotFound,
  },
  { path: '/analysen/analysenvorlagen/:objectId/anforderungsdetail/add',
    name: 'AnforderungsdetailForm',
    component: AnforderungsdetailForm,
    props: true,
    meta: { icon: 'mdi-format-list-checkbox', color: MODULE_ANALYSEN_COLOR }
  },
]

export default routes
