import { RouteRecordRaw } from 'vue-router'

const HistoryList = () => import('@/modules/history/HistoryList.vue')
const HistoryInterfacesList = () => import('@/modules/history/HistoryInterfacesList.vue')

const routes: RouteRecordRaw[] = [
  { path: '/history/history/',
    name: 'HistoryList',
    component: HistoryList,
    meta: { icon: 'mdi-history', color: '#2F4F4F' }
  },
  { path: '/history/interfaceshistory/',
    name: 'HistoryInterfacesList',
    component: HistoryInterfacesList,
    meta: { icon: 'mdi-history', color: '#2F4F4F' }
  },
]

export default routes
