import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export default function initSentry(app: App<Element>, router: Router) {
  if (['production', 'projekt'].includes(import.meta.env.MODE)) {
    console.debug('Initializing Sentry')
    Sentry.init({
      app,
      environment: import.meta.env.MODE || 'development',
      release: import.meta.env.VITE_APP_VERSION,
      dsn: 'https://79bb179874754ea9a737478a1e959da7@sentry.asix.pro/3',
      attachStacktrace: true,
      sampleRate: 0,
      debug: false,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.01,

      // https://docs.sentry.io/concepts/key-terms/tracing/distributed-tracing/
      tracePropagationTargets: ["asix.pro", "drschaer.com", "asix.elpo.eu"],

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1,
      tracesSampleRate: 0.2,

      integrations: [
        Sentry.vueIntegration({
          tracingOptions: {
            trackComponents: true,
            hooks: ["create", "mount", "update", "unmount", "destroy"],
          }
        }),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false
        }),
        Sentry.replayCanvasIntegration(),
        Sentry.captureConsoleIntegration(),
        Sentry.browserSessionIntegration(),
        Sentry.browserTracingIntegration({
          router,
          beforeStartSpan: context => {
            return {
              ...context,
              // You could use your UI's routing library to find the matching
              // route template here. We don't have one right now, so do some basic
              // parameter replacements.
              name: window.location.hash
                .replace(/\d+/g, "<digits>")
                .replace(/[a-f0-9]{32}/g, "<hash>"),
            };
          },
        })
      ],
    })
  }
}