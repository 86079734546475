import { createApp, provide, h, defineAsyncComponent } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import $ from 'jquery'

import App from '@/App.vue'
import { apolloClient } from '@/plugins/apollo'
import i18n from '@/plugins/i18n'
import pinia from '@/plugins/pinia'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import icons from '@/icons'

import '@/assets/main.css'
import '@fontsource/roboto/latin.css'
import initSentry from '@/plugins/sentry'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
})

// Global Vue Properties
app.config.globalProperties.$icons = icons

// Globale Installation von JQuery damit Kendo-UI funktioniert
declare global {
  interface Window {
    jQuery: JQueryStatic
    $: JQueryStatic
  }
}
window['jQuery'] = $
window['$'] = $

// Plugin-Installation
app.use(pinia)
app.use(i18n)
app.use(router)
app.use(vuetify)

initSentry(app, router)

// Registrierung globale Components
app.component('BaseForm', defineAsyncComponent(() => import('@/base/form/BaseForm.vue')))
app.component('DefaultCards', defineAsyncComponent(() => import('@/base/cards/DefaultCards.vue')))

app.mount('#app')

export default app